"use strict";

document.addEventListener("DOMContentLoaded", function () {
  let i = 0;
  const text = `Give a traffic go with a modern, business reflecting website!`;
  const speed = 115;
  const selectedWords = ["go", "modern", "website"];
  let typewriterStarted = false;
  let wrapperSlideUpStarted = false; // Flag to track if the wrapper has started sliding up

  function typeWriting() {
    if (i <= text.length) {
      let currentText = text.substring(0, i);
      selectedWords.forEach((word) => {
        let escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        let regex = new RegExp(`\\b${escapedWord}\\b`, "ig");
        currentText = currentText.replace(
          regex,
          `<span class="black-gradient">${word}</span>`
        );
      });
      document.getElementById("demo-1").innerHTML = currentText;
      i++;
      setTimeout(typeWriting, speed);
    } else {
      setTimeout(startFadeOut, 1000); // Fade out after typewriter effect completes
    }
  }

  function startFadeOut() {
    const demo = document.getElementById("demo-1");
    demo.style.transition = "opacity 1s ease-out"; // Smooth fade-out
    demo.style.opacity = 0;

    setTimeout(() => {
      demo.style.display = "none"; // Hide the typewriter text completely
      startShowHero(); // Show hero section after fade-out
    }, 1000);
  }

  function startShowHero() {
    const hero = document.querySelector(".hero");
    hero.style.transition = "opacity 1.5s ease-in"; // Smooth fade-in effect
    hero.style.opacity = 1; // Hero section becomes visible
  }

  function startSlideUp() {
    if (!wrapperSlideUpStarted) {
      wrapperSlideUpStarted = true; // Prevent repeated calls
      const wrapper = document.querySelector(".wrapper");
      wrapper.classList.add("slide-up"); // Slide-up animation for wrapper

      // Start typewriter effect after wrapper slides up
      if (!typewriterStarted) {
        typewriterStarted = true;
        setTimeout(typeWriting, 0); // Start typewriter effect immediately after slide-up
      }
    }
  }

  function removeWrapper() {
    const wrapper = document.querySelector(".wrapper");
    wrapper.style.display = "none"; // Completely hide the wrapper on scroll
  }

  // Determine delay based on screen width
  const isMobile = window.innerWidth <= 958;
  const delayBeforeSlideUp = isMobile ? 3000 : 4000; // 3 seconds for mobile, 10 seconds for desktop

  let timeoutID = setTimeout(() => {
    startSlideUp();
  }, delayBeforeSlideUp);
  // Mobile menu toggle
  document.addEventListener("DOMContentLoaded", function () {
    const menuToggle = document.getElementById("menu-toggle");
    const menu = document.querySelector(".menu");

    menuToggle.addEventListener("click", function (e) {
      e.preventDefault();
      menu.classList.toggle("active");
    });

    window.addEventListener("scroll", function () {
      const headerNav = document.querySelector("header nav");

      if (window.scrollY > 0) {
        // User is scrolling down
        headerNav.classList.add("nav-fixed");
      } else {
        // User is scrolling back up
        headerNav.classList.remove("nav-fixed");
      }
    });
  });

  // Handle scroll event
  window.addEventListener("scroll", function () {
    const headerNav = document.querySelector("header nav");
    const wrapper = document.querySelector(".wrapper");

    if (window.scrollY > 0) {
      // User is scrolling down
      clearTimeout(timeoutID); // Stop the wrapper slide-up if user scrolls before 10 seconds
      removeWrapper(); // Wrapper disappears
      headerNav.classList.add("nav-fixed");
    } else {
      // User is scrolling back up
      headerNav.classList.remove("nav-fixed");
      wrapper.style.display = "none"; // Wrapper remains hidden when scrolling back up
    }
  });
});
